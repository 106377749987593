import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/pagination"

import { EffectFade, Navigation, Pagination } from "swiper"
import { Layout, Seo, StickyLinks } from "@components"
import { Link, graphql } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import styled, { css } from "styled-components"

import { ImageResolver } from "@components"
import React from "react"
import { isEmpty } from "lodash"
import { page_wrapper } from "@css/global"
import sanitizeHtml from "sanitize-html"

const Post = ({ data: { post, next } }) => {
  function resolveHeroImages() {
    if (post?.contentTypeName === "project") {
      if (post?.projectFields?.projectGallery) {
        return post?.projectFields?.projectGallery?.concat([
          post?.featuredImage?.node,
        ])
      }
      return [post?.featuredImage?.node]
    } else if (post?.contentTypeName === "news") {
      if (post?.newsFields?.newsGallery) {
        return post?.newsFields?.newsGallery?.concat([
          post?.featuredImage?.node,
        ])
      }
      return [post?.featuredImage?.node]
    }
  }

  const FeaturedImages = ({ images }) => {
    return (
      <Swiper
        loop={true}
        modules={[EffectFade, Pagination, Navigation]}
        effect="fade"
        navigation={{
          nextEl: ".swiper-slide",
        }}
        autoplay={{ delay: 6500 }}
        pagination={{ clickable: true }}
      >
        {images?.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <ImageResolver {...image} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    )
  }

  return (
    <Layout background="#313b49">
      <StickyLinks
        prev={post?.stickyLinks?.previousLink}
        next={post?.stickyLinks?.nextLink}
      />

      <Seo
        {...{
          description: post?.seo?.metaDesc,
          meta: post?.seo?.metaDesc,
          title: post?.seo?.title,
        }}
      />
      <Wrapper postType={post?.contentTypeName}>
        <aside className="post__details">
          {post?.contentTypeName === "news" && (
            <h2>
              <span>{post?.dateGmt}</span>
              {post?.categories?.nodes?.length > 0 && (
                <>
                  <span> — </span>
                  <span>{post?.categories?.nodes?.[0]?.name}</span>
                </>
              )}
            </h2>
          )}
          {post?.contentTypeName === "project" && (
            <>
              <h1>{post?.title}</h1>
              {post?.projectFields?.projectLocation && (
                <h2>{post?.projectFields?.projectLocation}</h2>
              )}
              {post?.projectFields?.projectDate && (
                <h2>{post?.projectFields?.projectDate}</h2>
              )}
            </>
          )}
        </aside>
        <article className="post__content">
          {post?.featuredImage && (
            <FeaturedImages images={resolveHeroImages()} />
          )}
          {post.contentTypeName === "news" && (
            <h2 className="project__news-title">{post.title}</h2>
          )}
          <div
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(post?.content) }}
          ></div>
          <Link
            className="post__nav"
            to={
              post.contentTypeName === "project"
                ? "/services#projects"
                : "/news#articles"
            }
          >
            Back to {post.contentTypeName === "project" ? "projects" : "news"}
          </Link>
          {post.contentTypeName === "project" && !isEmpty(next) && (
            <Link className="post__nav" to={next?.uri}>
              Next Project: {next?.title}
            </Link>
          )}
        </article>
      </Wrapper>
    </Layout>
  )
}

export default Post

const Wrapper = styled.section`
  padding-top: calc(100vh + 6.5rem);
  padding-bottom: 6.5rem;

  @media (min-width: 64rem) {
    padding-top: 11rem;
  }

  ${page_wrapper};
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: minmax(0, 1fr);

  @media (min-width: 64rem) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  }

  * {
    color: var(--color-text-light);
  }

  .post__details {
    h1 {
      font-size: 1.875rem;
      margin-top: 0;
      font-weight: normal;
    }
    h2,
    h3 {
      font-weight: 300;
      font-size: 1.125rem;
      margin-top: 0;
      margin-bottom: 0.5rem;
      color: var(--color-accent);

      span {
        color: inherit;
      }
    }
  }

  .post__content {
    p {
      font-family: "Graphik-Light";
      font-size: 1rem;

      ${props => {
        if (props.postType === "project")
          return css`
            &:first-of-type {
              font-family: "Graphik-Regular";
              font-weight: 600;
            }
          `
      }}
    }

    ul {
      list-style-type: none;

      li {
        position: relative;
        font-weight: inherit;
        padding-left: 1.25rem;
        margin-bottom: 0.25rem;

        &:before {
          content: "—";
          position: absolute;
          left: 0;
        }
      }
    }

    .swiper {
      margin-bottom: 5rem;
      height: 100vh;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      overflow: visible;

      .swiper-pagination {
        padding-right: 1.5rem;
        bottom: -2.5rem;

        @media (min-width: 64rem) {
          padding-right: 0;
        }

        text-align: right;

        .swiper-pagination-bullet {
          margin: 0.15rem;
          background: transparent;
          border: 0.0625rem solid var(--color-text-light);
          opacity: 1;

          height: 0.75rem;
          width: 0.75rem;

          &.swiper-pagination-bullet-active {
            background: var(--color-text-light);
          }
        }
      }

      @media (min-width: 64rem) {
        height: auto;
        position: relative;
      }
    }

    .project__news-title {
      font-size: 1.5rem;
      margin-top: 0;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;

      @media (min-width: 64rem) {
        height: 0;
        padding-bottom: 66%;
      }
    }

    img {
      aspect-ratio: 3 / 2;
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .post__nav {
      display: block;
      margin-bottom: 0.5rem;
      color: var(--color-accent);

      &:first-of-type {
        margin-top: 3rem;
      }
    }
  }
`

export const pageQuery = graphql`
  query PostById($id: String!, $nextId: String) {
    next: wpContentNode(id: { eq: $nextId }) {
      ... on WpProject {
        title
        uri
      }
    }

    post: wpContentNode(id: { eq: $id }) {
      ... on WpNewsArticle {
        stickyLinks {
          nextLink {
            target
            title
            url
          }
          previousLink {
            target
            title
            url
          }
        }

        seo {
          metaDesc
          title
        }
        title
        content
        contentTypeName
        dateGmt(formatString: "D MMM YY")
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        newsFields {
          newsGallery {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
      ... on WpProject {
        stickyLinks {
          nextLink {
            target
            title
            url
          }
          previousLink {
            target
            title
            url
          }
        }
        seo {
          metaDesc
          title
        }
        title
        content
        contentTypeName
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        projectFields {
          projectLocation
          projectDate
          projectGallery {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
